import { useCallback, useEffect, useMemo, useState } from 'react'
import { IoIosCloudUpload, IoMdArrowRoundBack, IoIosWarning } from 'react-icons/io'
import { useParams } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import useAxios from 'axios-hooks'

import {
  CARDIAC_CYCLE_PHASES,
  DICOMS,
  DICOM_PHASE_LABELS,
  FEATURES,
  FRAMES,
  KEYPOINT_COLLECTIONS,
  KEYPOINT_COLLECTION_CLASSES,
  PERSPECTIVES,
  SEGMENTATIONS,
  STUDIES,
  VIEWPORTS,
} from '../../../constants/api'
import Canvas from './Canvas'
import Loader from './Loader'
import {
  Button,
  CanvasContainer,
  ContentContainer,
  HeaderContainer,
  IconContainer,
  LabelTag,
  LabelsContainer,
  PageContainer,
  RightSideBar,
  SideBarContainer,
  SmallLoaderContainer,
  StatusTag,
  ViewerContainer,
} from './Viewer.style'
import Dicoms from './Dicoms'
import Qualifier from './Qualifier'
import Identifier from './Identifier'
import Keypointer from './Kepointer'
import Segmentor from './Segmentor'
import Phaser from './Phaser'
import { useHotkeys } from 'react-hotkeys-hook'
import SubHeader from './SubHeader'

const Viewer = () => {
  const { annotationSetId, dicomId, studyId } = useParams()

  const [dicomSideBarIsOpen, setDicomSideBarIsOpen] = useState(true)

  const studyUrl = useMemo(() => `${STUDIES}${studyId}/`, [studyId])

  const framesParams = useMemo(() => ({ study: studyId, paginate: false }), [studyId])
  const dicomsParams = useMemo(() => ({ study: studyId, paginate: false }), [studyId])

  const perspectiveParams = useMemo(() => ({ dicom__study__uuid: studyId, paginate: false }), [studyId])
  const phaseLabelParams = useMemo(() => ({ dicom__study__uuid: studyId, paginate: false }), [studyId])
  const keypointCollectionParams = useMemo(
    () => ({ frame__media__dicom__study__uuid: studyId, paginate: false }),
    [studyId],
  )
  const segmentationsParams = useMemo(() => ({ frame__media__dicom__study__uuid: studyId, paginate: false }), [studyId])

  const [{ data: studyResponse, loading: studyResponseIsLoading }] = useAxios(studyUrl)
  const [{ data: dicomsResponse, loading: dicomsResponseIsLoading }] = useAxios({
    url: DICOMS,
    params: dicomsParams,
  })
  const [{ data: framesResponse, loading: framesResponseIsLoading }] = useAxios({
    url: FRAMES,
    params: framesParams,
  })

  const [{ data: viewportsResponse, loading: viewportsResponseIsLoading }] = useAxios({
    url: VIEWPORTS,
    params: { size: 300 },
  })
  const [{ data: cardiacCyclePhasesResponse, loading: cardiacCyclePhasesResponseIsLoading }] =
    useAxios(CARDIAC_CYCLE_PHASES)
  const [{ data: keypointCollectionClassesResponse, loading: keypointCollectionClassesResponseIsLoading }] =
    useAxios(KEYPOINT_COLLECTION_CLASSES)
  const [{ data: featuresResponse, loading: featuresResponseIsLoading }] = useAxios(FEATURES)

  const [{ data: perspectivesResponse, loading: perspectiveResponseIsLoading }, getPerspectives] = useAxios(
    {
      url: PERSPECTIVES,
      params: perspectiveParams,
    },
    { useCache: false, manual: true },
  )
  const [{ data: dicomPhaseLabelsResponse, loading: dicomPhaseLabelsResponseIsLoading }, getPhaseLabels] = useAxios(
    {
      url: DICOM_PHASE_LABELS,
      params: phaseLabelParams,
    },
    { useCache: false, manual: true },
  )
  const [{ data: keypointCollectionsResponse, loading: keypointCollectionsResponseIsLoading }, getKeyPointCollections] =
    useAxios(
      {
        url: KEYPOINT_COLLECTIONS,
        params: keypointCollectionParams,
      },
      { useCache: false, manual: true },
    )
  const [{ data: segmentationsResponse, loading: segmentationsResponseIsLoading }, getSegmentations] = useAxios(
    {
      url: SEGMENTATIONS,
      params: segmentationsParams,
    },
    { useCache: false, manual: true },
  )

  const [activeTool, setActiveTool] = useState('identifier')
  const [activeFrameIndex, setActiveFrameIndex] = useState(0)
  const [dicom, setDicom] = useState([])
  const [frames, setFrames] = useState([])
  const [videoState, setVideoState] = useState(false)
  const [intervalId, setIntervalId] = useState()

  const [dataIsLoading, setDataIsLoading] = useState(false)
  const [dataIsSaving, setDataIsSaving] = useState(false)
  const [errorOccured, setErrorOccured] = useState(false)

  const [perspectiveDataIsSaving, setPerspectiveDataIsSaving] = useState(false)
  const [phaseLabelIsSaving, setPhaseLabelIsSaving] = useState(false)
  const [keypointCollectionsIsSaving, setKeypointCollectionsIsSaving] = useState(false)
  const [segmentationsIsSaving, setSegmentationsIsSaving] = useState(false)

  const [perspectiveChangesAreSaved, setPerspectiveChangesAreSaved] = useState(true)
  const [dicomPhaseLabelChangesAreSaved, setDicomPhaseLabelChangesAreSaved] = useState(true)
  const [keypointCollectionChangesAreSaved, setKeypointCollectionsChangesAreSaved] = useState(true)
  const [segmentationChangesAreSaved, setSegmentationChangesAreSaved] = useState(true)
  const [dicomJobChangesAreSaved, setDicomJobChangesAreSaved] = useState(true)

  const [perspectives, setPerspectives] = useState(null)
  const [dicomPhaseLabels, setDicomPhaseLabels] = useState(null)
  const [keypointCollections, setKeypointCollections] = useState(null)
  const [segmentations, setSegmentations] = useState(null)

  const [activeKeypointCollectionClass, setActiveKeypointCollectionClass] = useState(null)
  const [activeFeature, setActiveFeature] = useState(null)

  const [showMeasurements, setShowMeasurements] = useState(true)

  const [useOpenContours, setUseOpenContours] = useState(false)
  const [handleCompleteContour, triggerCompleteContour] = useState(false)

  const backToDatasetLink = `/studies/${annotationSetId}`
  const study = studyResponse
  const dicoms = studyResponse?.dicoms?.sort((a, b) => a?.index - b?.index)

  const viewports = viewportsResponse?.results
  const cardiacCyclePhases = cardiacCyclePhasesResponse?.results
  const keypointCollectionClasses = keypointCollectionClassesResponse?.results
  const features = featuresResponse?.results

  const numberOfFrames = frames?.length
  const numberOfSeconds = 1000 / dicom?.framesPerSecond

  const identifierIsActive = activeTool === 'identifier'
  const phaserIsActive = activeTool === 'phaser'
  const keypointerIsActive = activeTool === 'keypointer'
  const segmentorIsActive = activeTool === 'segmentor'
  const identifierIsDisabled = false
  const phaserIsDisabled = false
  const keypointerIsDisabled = false
  const segmentorIsDisabled = false

  const checkDicomForLabeledPerspective = useCallback(
    (dicom) => {
      const perspective = perspectives?.find((perspective) => perspective?.dicom === dicom?.uuid)
      const perspectiveIsLabeled = !!(perspective?.viewport && perspective?.quantifiability)
      const perspectiveIsRequired = true
      return {
        perspectiveIsLabeled,
        perspectiveIsRequired,
      }
    },
    [perspectives],
  )

  const checkDicomForLabeledPhases = useCallback(
    (dicom) => {
      const perspective = perspectives?.find((perspective) => perspective?.dicom === dicom?.uuid)
      const requiredPhases = cardiacCyclePhases
        ?.filter((cardiacCyclePhase) => cardiacCyclePhase?.viewports?.includes(perspective?.viewport))
        ?.map((cardiacCyclePhase) => cardiacCyclePhase?.value)
      const dicomPhaseLabelsAreRequired = !!requiredPhases?.length

      const phaseLabels = dicomPhaseLabels?.filter((dicomPhaseLabel) => dicomPhaseLabel?.dicom === dicom?.uuid)
      const phases = phaseLabels?.map((dicomPhaseLabel) => dicomPhaseLabel?.phase)

      const dicomPhaseLabelsAreLabeled =
        phases?.length > 0 && requiredPhases?.every((requiredPhase) => phases?.includes(requiredPhase))

      return {
        dicomPhaseLabelsAreLabeled,
        dicomPhaseLabelsAreRequired,
      }
    },
    [cardiacCyclePhases, dicomPhaseLabels, perspectives],
  )

  const checkDicomForLabeledKeypoints = useCallback(
    (dicom) => {
      const frames = framesResponse
      const frameUuids = frames?.filter((frame) => frame?.dicom === dicom?.uuid)?.map((frame) => frame?.uuid)
      const phaseLabels = dicomPhaseLabels?.filter((dicomPhaseLabel) => dicomPhaseLabel?.dicom === dicom?.uuid)
      const perspective = perspectives?.find((perspective) => perspective?.dicom === dicom?.uuid)

      const phaseFrameUuids = phaseLabels?.map((dicomPhaseLabel) => dicomPhaseLabel?.frame)

      const hasPhasesForKeypoints = !!phaseFrameUuids?.length

      const requiredKeypointCollectionClasses = keypointCollectionClasses
        ?.filter((keypointCollectionClass) => keypointCollectionClass?.viewports?.includes(perspective?.viewport))
        ?.map((keypointCollectionClass) => keypointCollectionClass?.value)
      const keypointCollectionsAreRequired = !!requiredKeypointCollectionClasses?.length

      const labeledKeypointCollections = keypointCollections?.filter((keypointCollection) => {
        const frameIds = hasPhasesForKeypoints ? phaseFrameUuids : frameUuids
        return (
          frameIds?.includes(keypointCollection?.frame) &&
          requiredKeypointCollectionClasses?.includes(keypointCollection?.keyPointCollectionClass) &&
          !!keypointCollection?.keyPoints?.length &&
          keypointCollection?.keyPoints?.every((keypoint) => keypoint?.x && keypoint?.y)
        )
      })
      const numberOfKeypointPhases = phaseFrameUuids?.length || 1
      const keypointCollectionsAreLabeled =
        labeledKeypointCollections?.length > 0 &&
        labeledKeypointCollections?.length === requiredKeypointCollectionClasses?.length * numberOfKeypointPhases

      return {
        keypointCollectionsAreLabeled,
        keypointCollectionsAreRequired,
      }
    },
    [framesResponse, keypointCollectionClasses, perspectives, keypointCollections, dicomPhaseLabels],
  )

  const checkDicomForLabeledSegmentations = useCallback(
    (dicom) => {
      const frames = framesResponse
      const frameUuids = frames?.filter((frame) => frame?.dicom === dicom?.uuid)?.map((frame) => frame?.uuid)
      const phaseLabels = dicomPhaseLabels?.filter((dicomPhaseLabel) => dicomPhaseLabel?.dicom === dicom?.uuid)
      const perspective = perspectives?.find((perspective) => perspective?.dicom === dicom?.uuid)

      const segmentationPhaseFrameUuids = phaseLabels
        ?.filter((dicomPhaseLabel) => dicomPhaseLabel?.phase !== 'Mid-Systole')
        ?.map((dicomPhaseLabel) => dicomPhaseLabel?.frame)
      const hasPhasesForSegmentations = !!segmentationPhaseFrameUuids?.length

      const requiredFeatures = features
        ?.filter((feature) => feature?.viewports?.includes(perspective?.viewport))
        ?.map((feature) => feature?.value)
      const segmentationsAreRequired = !!requiredFeatures?.length
      const numberOfSegmentationPhases = segmentationPhaseFrameUuids?.length || 1

      const labeledSegmentations = segmentations?.filter((segmentation) => {
        const frameIds = hasPhasesForSegmentations ? segmentationPhaseFrameUuids : frameUuids
        return (
          frameIds?.includes(segmentation?.frame) &&
          requiredFeatures?.includes(segmentation?.feature?.value) &&
          (!!segmentation?.paths?.length || segmentation?.isFullyVisible === false)
        )
      })
      const segmentationsAreLabeled =
        labeledSegmentations?.length > 0 &&
        labeledSegmentations?.length === requiredFeatures?.length * numberOfSegmentationPhases

      return {
        segmentationsAreLabeled,
        segmentationsAreRequired,
      }
    },
    [dicomPhaseLabels, features, framesResponse, perspectives, segmentations],
  )

  const checkIfDicomIsLabeled = useCallback(
    (dicom) => {
      const { perspectiveIsLabeled, perspectiveIsRequired } = checkDicomForLabeledPerspective(dicom)
      const { dicomPhaseLabelsAreLabeled, dicomPhaseLabelsAreRequired } = checkDicomForLabeledPhases(dicom)
      const { keypointCollectionsAreLabeled, keypointCollectionsAreRequired } = checkDicomForLabeledKeypoints(dicom)
      const { segmentationsAreLabeled, segmentationsAreRequired } = checkDicomForLabeledSegmentations(dicom)

      const dicomIsLabeled =
        perspectiveIsLabeled &&
        (dicomPhaseLabelsAreLabeled || !dicomPhaseLabelsAreRequired) &&
        (keypointCollectionsAreLabeled || !keypointCollectionsAreRequired) &&
        (segmentationsAreLabeled || !segmentationsAreRequired)
      return {
        perspectiveIsLabeled,
        perspectiveIsRequired,
        dicomPhaseLabelsAreLabeled,
        dicomPhaseLabelsAreRequired,
        keypointCollectionsAreLabeled,
        keypointCollectionsAreRequired,
        segmentationsAreLabeled,
        segmentationsAreRequired,
        dicomIsLabeled,
      }
    },
    [
      checkDicomForLabeledKeypoints,
      checkDicomForLabeledPerspective,
      checkDicomForLabeledPhases,
      checkDicomForLabeledSegmentations,
    ],
  )

  const activeFrame = useMemo(() => frames?.[activeFrameIndex], [frames, activeFrameIndex])
  const activeDicom = useMemo(() => dicoms?.find((dicom) => dicom?.uuid === dicomId), [dicomId, dicoms])
  const activePerspective = useMemo(
    () => perspectives?.find((perspective) => perspective?.dicom === dicomId),
    [dicomId, perspectives],
  )
  const activeCardiacPhases = useMemo(
    () => cardiacCyclePhases?.filter((cardiacPhase) => cardiacPhase.viewports?.includes(activePerspective?.viewport)),
    [activePerspective?.viewport, cardiacCyclePhases],
  )
  const activeDicomPhaseLabels = useMemo(() => {
    return dicomPhaseLabels?.filter((dicomPhase) => dicomPhase?.dicom === dicomId)
  }, [dicomId, dicomPhaseLabels])
  const activeKeypointCollection = useMemo(
    () =>
      keypointCollections?.find(
        (keypointCollection) =>
          keypointCollection?.frame === activeFrame?.uuid &&
          keypointCollection?.keyPointCollectionClass === activeKeypointCollectionClass?.value,
      ),
    [activeFrame?.uuid, activeKeypointCollectionClass?.value, keypointCollections],
  )

  const activeSegmentation = useMemo(
    () =>
      segmentations?.find(
        (segmentation) =>
          segmentation?.frame === activeFrame?.uuid && segmentation?.feature?.value === activeFeature?.value,
      ),
    [activeFeature?.value, activeFrame?.uuid, segmentations],
  )

  const viewport = useMemo(
    () => viewports?.find((viewport) => viewport?.uuid === activePerspective?.viewport),
    [activePerspective?.viewport, viewports],
  )

  const dicomViewports = useMemo(
    () => viewports?.filter((viewport) => viewport?.type === dicom?.type && viewport?.mediaType === dicom?.mediaType),
    [viewports, dicom],
  )
  const dicomKeypointCollectionClasses = useMemo(
    () =>
      keypointCollectionClasses?.filter((keypointCollectionClass) =>
        keypointCollectionClass?.viewports?.includes(viewport?.uuid),
      ),
    [keypointCollectionClasses, viewport?.uuid],
  )
  const dicomFeatures = useMemo(
    () => features?.filter((feature) => feature?.viewports?.includes(viewport?.uuid)),
    [features, viewport?.uuid],
  )

  const {
    perspectiveIsLabeled,
    dicomPhaseLabelsAreLabeled,
    keypointCollectionsAreLabeled,
    segmentationsAreLabeled,
    dicomIsLabeled,
  } = checkIfDicomIsLabeled(activeDicom)

  const showPhaser = useMemo(() => !!activeCardiacPhases?.length, [activeCardiacPhases?.length])
  const showKeypointer = useMemo(
    () => !!dicomKeypointCollectionClasses?.length,
    [dicomKeypointCollectionClasses?.length],
  )
  const showSegmentor = useMemo(() => !!dicomFeatures?.length, [dicomFeatures?.length])

  const previousButtonIsDisabled = activeTool === 'identifier'
  const nextButtonIsDisabled =
    activeTool === 'identifier'
      ? showPhaser || showKeypointer || showSegmentor
        ? false
        : true
      : activeTool === 'phaser'
        ? showKeypointer || showSegmentor
          ? false
          : true
        : activeTool === 'keypointer'
          ? showSegmentor
            ? false
            : true
          : true

  const showWindowAndViewTag = useMemo(() => perspectiveIsLabeled, [perspectiveIsLabeled])
  const showPhaseTag = useMemo(
    () => showPhaser && showWindowAndViewTag && dicomPhaseLabelsAreLabeled,
    [dicomPhaseLabelsAreLabeled, showPhaser, showWindowAndViewTag],
  )
  const showKeypointTag = useMemo(
    () => showKeypointer && showPhaseTag && keypointCollectionsAreLabeled,
    [keypointCollectionsAreLabeled, showKeypointer, showPhaseTag],
  )
  const showSegmentationTag = useMemo(
    () => showSegmentor && showKeypointTag && segmentationsAreLabeled,
    [showSegmentor, showKeypointTag, segmentationsAreLabeled],
  )

  const windowAndViewTag = useMemo(() => `${viewport?.window} ${viewport?.view}`, [viewport?.view, viewport?.window])
  const phaseTag = useMemo(() => `${activeDicomPhaseLabels?.length} Phases`, [activeDicomPhaseLabels?.length])
  const keypointTag = useMemo(() => {
    const phaseFrameUuids = activeDicomPhaseLabels?.map((phase) => phase?.frame)
    const keypointCollectionClassValues = dicomKeypointCollectionClasses?.map(
      (keypointCollectionClass) => keypointCollectionClass?.value,
    )
    const labeledKeypointCollections = keypointCollections?.filter(
      (keypointCollection) =>
        phaseFrameUuids?.includes(keypointCollection?.frame) &&
        keypointCollectionClassValues?.includes(keypointCollection?.keyPointCollectionClass) &&
        !!keypointCollection?.keyPoints?.length &&
        keypointCollection?.keyPoints?.every((keypoint) => keypoint?.x && keypoint?.y),
    )
    return `${labeledKeypointCollections?.length} Keypoint Collections`
  }, [activeDicomPhaseLabels, dicomKeypointCollectionClasses, keypointCollections])
  const segmentationTag = useMemo(() => {
    const usablePhases = activeDicomPhaseLabels?.filter((dicomPhase) => dicomPhase?.phase !== 'Mid-Systole')
    const phaseFrameUuids = usablePhases?.map((phase) => phase?.frame)
    const dicomFeatureValues = dicomFeatures?.map((feature) => feature?.value)
    const labeledSegmentations = segmentations?.filter(
      (segmentation) =>
        phaseFrameUuids?.includes(segmentation?.frame) &&
        dicomFeatureValues?.includes(segmentation?.feature?.value) &&
        !!segmentation?.paths?.length,
    )
    return `${labeledSegmentations?.length} Segmentations`
  }, [activeDicomPhaseLabels, dicomFeatures, segmentations])

  const changesAreSaved = useMemo(
    () =>
      perspectiveChangesAreSaved &&
      dicomPhaseLabelChangesAreSaved &&
      keypointCollectionChangesAreSaved &&
      segmentationChangesAreSaved &&
      dicomJobChangesAreSaved,
    [
      dicomPhaseLabelChangesAreSaved,
      keypointCollectionChangesAreSaved,
      perspectiveChangesAreSaved,
      segmentationChangesAreSaved,
      dicomJobChangesAreSaved,
    ],
  )

  const handleTriggerCompleteContour = () => {
    triggerCompleteContour((prev) => !prev)
  };

  const handleDicomTypeOnClick = () => {
    const typeToggleMap = {
      'Color': 'Standard',
      'Standard': 'Color',
      'ICE Color': 'ICE Standard',
      'ICE Standard': 'ICE Color',
    }

    const type = typeToggleMap[dicom?.type] || 'Standard' // Fallback to 'Standard' if type is undefined

    setDicom((dicom) => ({
      ...dicom,
      type,
    }))
  }

  const setPerspective = useCallback(
    (perspective) => {
      const nonActivePerspectives = perspectives?.filter((perspective) => perspective?.dicom !== dicomId)
      setPerspectives([...nonActivePerspectives, perspective])
      setPerspectiveChangesAreSaved(false)
    },
    [dicomId, perspectives],
  )

  const setDicomPhase = useCallback(
    (dicomPhase) => {
      const nonActiveDicomPhases = dicomPhaseLabels?.filter(
        (dicomPhaseLabel) =>
          dicomPhaseLabel?.frame !== activeFrame?.uuid &&
          !(dicomPhaseLabel?.phase === dicomPhase?.phase && dicomPhaseLabel?.dicom === dicomPhase?.dicom),
      )
      setDicomPhaseLabels([...nonActiveDicomPhases, dicomPhase])
      setDicomPhaseLabelChangesAreSaved(false)
    },
    [activeFrame?.uuid, dicomPhaseLabels],
  )

  const setKeypointCollection = useCallback(
    (keypointCollection) => {
      const nonActiveKeypointCollections = keypointCollections?.filter(
        (keypointCollection) =>
          keypointCollection?.frame !== activeFrame?.uuid ||
          keypointCollection?.keyPointCollectionClass !== activeKeypointCollectionClass?.value,
      )
      const newKeypointCollection = {
        ...keypointCollection,
        frame: activeFrame?.uuid,
        frameIndex: activeFrameIndex,
        keyPointCollectionClass: activeKeypointCollectionClass?.value,
      }
      setKeypointCollections([...nonActiveKeypointCollections, newKeypointCollection])
      setKeypointCollectionsChangesAreSaved(false)
    },
    [activeFrame?.uuid, activeFrameIndex, activeKeypointCollectionClass?.value, keypointCollections],
  )

  const setSegmentation = useCallback(
    (newSegmentation) => {
      const nonActiveSegmentations = segmentations?.filter(
        (segmentation) =>
          !(segmentation?.frame === activeFrame?.uuid && segmentation?.feature?.value === activeFeature?.value),
      )
      setSegmentations([
        ...nonActiveSegmentations,
        {
          ...newSegmentation,
          feature: activeFeature,
          frame: activeFrame?.uuid,
        },
      ])
      setSegmentationChangesAreSaved(false)
    },
    [activeFeature, activeFrame?.uuid, segmentations],
  )

  // keypointer tool:
  const activeKeypoints = useMemo(() => {
    const keypoints = activeKeypointCollectionClass?.keyPointClasses?.map((keyPointClass) => {
      const keypoint = activeKeypointCollection?.keyPoints?.find(
        (keypoint) => keypoint?.keyPointClass === keyPointClass?.value,
      )
      const isLabeled = !!keypoint?.x && !!keypoint?.y
      const isActive =
        keypoint?.isActive === undefined && !isLabeled && keyPointClass?.order === 1 ? true : keypoint?.isActive

      return {
        ...keypoint,
        order: keyPointClass?.order,
        frame: activeFrame?.uuid,
        frameIndex: activeFrameIndex,
        keyPointClass: keyPointClass?.value,
        keyPointClassUuid: keyPointClass?.uuid,
        keyPointPair: keyPointClass?.keyPointPair,
        isActive,
        isLabeled,
      }
    })
    return keypoints
  }, [
    activeFrame?.uuid,
    activeFrameIndex,
    activeKeypointCollection?.keyPoints,
    activeKeypointCollectionClass?.keyPointClasses,
  ])

  const setKeypoints = useCallback(
    (keypoints) => {
      setKeypointCollection({
        ...activeKeypointCollection,
        keyPoints: keypoints,
      })
    },
    [activeKeypointCollection, setKeypointCollection],
  )

  const playVideo = useCallback(
    (speed) => {
      setVideoState(speed)
      const fps = numberOfSeconds * speed
      const id = setInterval(() => {
        setActiveFrameIndex((activeFrameIndex) => {
          if (activeFrameIndex === numberOfFrames - 1) return 0
          return activeFrameIndex + 1
        })
      }, fps)
      setIntervalId(id)
    },
    [numberOfFrames, numberOfSeconds],
  )

  const pauseVideo = useCallback(() => {
    if (intervalId !== null) {
      setVideoState(0)
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }, [intervalId])

  useEffect(() => setActiveTool('identifier'), [dicomId])

  useEffect(() => {
    if (!dicomId) return
    pauseVideo()
    setActiveFrameIndex(0)
    setPerspectiveChangesAreSaved(true)
    setDicomPhaseLabelChangesAreSaved(true)
    setKeypointCollectionsChangesAreSaved(true)
    setSegmentationChangesAreSaved(true)
    setDicomJobChangesAreSaved(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dicomId])

  useEffect(() => {
    if (!dicomsResponse | !dicomId) return
    setDicom(dicomsResponse?.find((dicom) => dicom?.uuid === dicomId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dicomsResponse, dicomId])

  useEffect(() => {
    if (!framesResponse || !dicomId) return
    setFrames(framesResponse?.filter((frame) => frame.dicom === dicomId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [framesResponse, dicomId])

  useEffect(() => {
    if (!studyId) return
    getPerspectives()
    getPhaseLabels()
    getKeyPointCollections()
    getSegmentations()
  }, [studyId, getKeyPointCollections, getPhaseLabels, getSegmentations, getPerspectives])

  useEffect(() => setPerspectives(perspectivesResponse), [perspectivesResponse])
  useEffect(() => setDicomPhaseLabels(dicomPhaseLabelsResponse), [dicomPhaseLabelsResponse])
  useEffect(() => setKeypointCollections(keypointCollectionsResponse), [keypointCollectionsResponse])
  useEffect(() => setSegmentations(segmentationsResponse), [segmentationsResponse])

  useEffect(
    () => setActiveKeypointCollectionClass(dicomKeypointCollectionClasses?.[0]),
    [dicomKeypointCollectionClasses],
  )
  useEffect(() => setActiveFeature(dicomFeatures?.[0]), [dicomFeatures])

  // wait 1 second for all components to mount
  useEffect(() => {
    const apisAreLoading =
      studyResponseIsLoading ||
      dicomsResponseIsLoading ||
      framesResponseIsLoading ||
      perspectiveResponseIsLoading ||
      dicomPhaseLabelsResponseIsLoading ||
      keypointCollectionsResponseIsLoading ||
      segmentationsResponseIsLoading ||
      viewportsResponseIsLoading ||
      cardiacCyclePhasesResponseIsLoading ||
      keypointCollectionClassesResponseIsLoading ||
      featuresResponseIsLoading

    if (apisAreLoading) {
      setDataIsLoading(true)
      const apisLoaded = new Promise((resolve) => {
        const checkApis = () => (apisAreLoading ? setTimeout(checkApis, 1000) : resolve())
        checkApis()
      })

      const oneSecondWait = new Promise((resolve) => setTimeout(resolve, 1000))

      Promise.all([apisLoaded, oneSecondWait]).then(() => setDataIsLoading(false))
    } else setTimeout(() => setDataIsLoading(false), 1000)
  }, [
    studyResponseIsLoading,
    viewportsResponseIsLoading,
    perspectiveResponseIsLoading,
    dicomPhaseLabelsResponseIsLoading,
    keypointCollectionsResponseIsLoading,
    segmentationsResponseIsLoading,
    cardiacCyclePhasesResponseIsLoading,
    keypointCollectionClassesResponseIsLoading,
    featuresResponseIsLoading,
    dicomsResponseIsLoading,
    framesResponseIsLoading,
  ])

  // wait 1 second for data to save
  useEffect(() => {
    const apisAreLoading =
      perspectiveDataIsSaving || phaseLabelIsSaving || keypointCollectionsIsSaving || segmentationsIsSaving

    if (apisAreLoading) {
      setDataIsSaving(true)
      const apisLoaded = new Promise((resolve) => {
        const checkApis = () => (apisAreLoading ? setTimeout(checkApis, 2000) : resolve())
        checkApis()
      })

      const oneSecondWait = new Promise((resolve) => setTimeout(resolve, 2000))

      Promise.all([apisLoaded, oneSecondWait]).then(() => setDataIsSaving(false))
    } else setTimeout(() => setDataIsSaving(false), 2000)
  }, [keypointCollectionsIsSaving, perspectiveDataIsSaving, phaseLabelIsSaving, segmentationsIsSaving])

  useHotkeys('R', (event) => {
    event.preventDefault()
    if (previousButtonIsDisabled) return
    setActiveTool((activeTool) =>
      activeTool === 'phaser'
        ? 'identifier'
        : activeTool === 'keypointer'
          ? showPhaser
            ? 'phaser'
            : 'identifier'
          : activeTool === 'segmentor'
            ? showKeypointer
              ? 'keypointer'
              : showPhaser
                ? 'phaser'
                : 'identifier'
            : 'identifier',
    )
  })

  useHotkeys('Y', (event) => {
    event.preventDefault()
    if (nextButtonIsDisabled) return
    setActiveTool((activeTool) =>
      activeTool === 'identifier'
        ? showPhaser
          ? 'phaser'
          : showKeypointer
            ? 'keypointer'
            : showSegmentor
              ? 'segmentor'
              : 'identifier'
        : activeTool === 'phaser'
          ? showKeypointer
            ? 'keypointer'
            : showSegmentor
              ? 'segmentor'
              : 'identifier'
          : activeTool === 'keypointer'
            ? showSegmentor
              ? 'segmentor'
              : 'identifier'
            : 'identifier',
    )
  })

  const showPageLoader = dataIsLoading
  const showSavingLoader = dataIsSaving
  const showErrorIcon = !dataIsSaving && errorOccured
  const showUnsavedChanges = !dataIsSaving && !showErrorIcon && !changesAreSaved

  return (
    <ViewerContainer>
      <HeaderContainer>
        <Button>
          <a href={backToDatasetLink}>
            <IoMdArrowRoundBack />
            <span>Back to Dataset</span>
          </a>
        </Button>
        <LabelsContainer>
          {!showPageLoader && (
            <>
              <StatusTag isHidden={dataIsLoading} isLabeled={dicomIsLabeled}>
                {dicomIsLabeled ? 'Labeled' : ''}
              </StatusTag>
              {showSegmentationTag && <LabelTag type="segmentor">{segmentationTag}</LabelTag>}
              {showKeypointTag && <LabelTag type="keypointer">{keypointTag}</LabelTag>}
              {showPhaseTag && <LabelTag type="phaser">{phaseTag}</LabelTag>}
              {showWindowAndViewTag && <LabelTag type="identifier">{windowAndViewTag}</LabelTag>}
              <LabelTag onClick={handleDicomTypeOnClick} type="type">
                {dicom?.type}
              </LabelTag>
            </>
          )}
          <SmallLoaderContainer>
            <IconContainer isHidden={!showUnsavedChanges} size={30}>
              <IoIosCloudUpload color="#9b257b" />
            </IconContainer>
            <IconContainer isHidden={!showErrorIcon} size={30}>
              <IoIosWarning color="red" />
            </IconContainer>
            <IconContainer isHidden={!showSavingLoader}>
              <Oval
                visible={true}
                height="25"
                width="25"
                color="#222"
                secondaryColor="#ccc"
                ariaLabel="oval-loading"
                wrapperClass="SpinnerContainer"
                strokeWidth={4}
              />
            </IconContainer>
          </SmallLoaderContainer>
        </LabelsContainer>
      </HeaderContainer>
      <PageContainer>
        <SubHeader
          showPageLoader={showPageLoader}
          study={study}
          dicoms={dicoms}
          checkIfDicomIsLabeled={checkIfDicomIsLabeled}
          previousButtonIsDisabled={previousButtonIsDisabled}
          nextButtonIsDisabled={nextButtonIsDisabled}
          identifierIsDisabled={identifierIsDisabled}
          identifierIsActive={identifierIsActive}
          setActiveTool={setActiveTool}
          showPhaser={showPhaser}
          phaserIsDisabled={phaserIsDisabled}
          phaserIsActive={phaserIsActive}
          showKeypointer={showKeypointer}
          keypointerIsDisabled={keypointerIsDisabled}
          keypointerIsActive={keypointerIsActive}
          showSegmentor={showSegmentor}
          segmentorIsDisabled={segmentorIsDisabled}
          segmentorIsActive={segmentorIsActive}
          dicom={dicom}
          frames={frames}
          activeDicomPhaseLabels={activeDicomPhaseLabels}
          keypointCollections={keypointCollections}
          segmentations={segmentations}
          activePerspective={activePerspective}
          setDataIsSaving={setDataIsSaving}
          setErrorOccured={setErrorOccured}
          setPerspectiveChangesAreSaved={setPerspectiveChangesAreSaved}
          setDicomPhaseLabelChangesAreSaved={setDicomPhaseLabelChangesAreSaved}
          setKeypointCollectionsChangesAreSaved={setKeypointCollectionsChangesAreSaved}
          setSegmentationChangesAreSaved={setSegmentationChangesAreSaved}
          setDicomJobChangesAreSaved={setDicomJobChangesAreSaved}
        />
        <ContentContainer>
          <SideBarContainer isHidden={!showPageLoader} style={{ minWidth: '300px', maxWidth: '300px' }} />
          <RightSideBar isHidden={showPageLoader} isOpen={dicomSideBarIsOpen}>
            <SideBarContainer>
              <Dicoms
                dicoms={dicoms}
                checkIfDicomIsLabeled={checkIfDicomIsLabeled}
                changesAreSaved={changesAreSaved}
                dicomSideBarIsOpen={dicomSideBarIsOpen}
                setDicomSideBarIsOpen={setDicomSideBarIsOpen}
              />
            </SideBarContainer>
          </RightSideBar>
          <CanvasContainer isHidden={!showPageLoader}>
            <Loader />
          </CanvasContainer>
          <CanvasContainer isHidden={showPageLoader}>
            <Canvas
              study={study}
              dicom={dicom}
              dicoms={dicoms}
              changesAreSaved={changesAreSaved}
              frames={frames}
              playVideo={playVideo}
              pauseVideo={pauseVideo}
              videoState={videoState}
              activeFrame={activeFrame}
              activeKeypoints={activeKeypoints}
              dicomPhases={activeDicomPhaseLabels}
              setFrames={setFrames}
              setActiveFrame={setActiveFrameIndex}
              setKeypoints={setKeypoints}
              showMeasurements={showMeasurements}
              keypointerIsActive={keypointerIsActive}
              segmentorIsActive={segmentorIsActive}
              activeSegmentation={activeSegmentation}
              setActiveSegmentation={setSegmentation}
              useOpenContours={useOpenContours}
              triggerCompleteContour={handleCompleteContour}
            />
          </CanvasContainer>
          <SideBarContainer isHidden={!showPageLoader} style={{ minWidth: '300px', maxWidth: '300px' }} />
          <RightSideBar isHidden={showPageLoader} isOpen>
            {identifierIsActive && (
              <Qualifier
                dicom={dicom}
                viewports={dicomViewports}
                viewport={viewport}
                perspective={activePerspective}
                setPerspective={setPerspective}
                setDataIsSaving={setPerspectiveDataIsSaving}
                setErrorOccured={setErrorOccured}
                setActiveTool={setActiveTool}
              />
            )}
            <SideBarContainer isHidden={showPageLoader}>
              {identifierIsActive && (
                <Identifier
                  dicom={dicom}
                  viewports={dicomViewports}
                  viewport={viewport}
                  perspective={activePerspective}
                  setPerspective={setPerspective}
                  setDataIsSaving={setPerspectiveDataIsSaving}
                  setErrorOccured={setErrorOccured}
                  setActiveTool={setActiveTool}
                />
              )}
              {showPhaser && phaserIsActive && (
                <Phaser
                  activeFrame={activeFrame}
                  dicom={dicom}
                  cardiacPhases={activeCardiacPhases}
                  dicomPhases={activeDicomPhaseLabels}
                  setDicomPhase={setDicomPhase}
                  setDataIsSaving={setPhaseLabelIsSaving}
                  setErrorOccured={setErrorOccured}
                  setActiveTool={setActiveTool}
                />
              )}
              {showKeypointer && keypointerIsActive && (
                <Keypointer
                  activeFrameIndex={activeFrameIndex}
                  activeFrame={activeFrame}
                  dicomPhases={activeDicomPhaseLabels}
                  activeKeypoints={activeKeypoints}
                  keypointCollectionClasses={dicomKeypointCollectionClasses}
                  activeKeypointCollectionClass={activeKeypointCollectionClass}
                  keypointCollections={keypointCollections}
                  showMeasurements={showMeasurements}
                  showPhaser={showPhaser}
                  setActiveFrameIndex={setActiveFrameIndex}
                  setActiveKeypointCollectionClass={setActiveKeypointCollectionClass}
                  setKeypoints={setKeypoints}
                  setShowMeasurements={setShowMeasurements}
                />
              )}
              {showSegmentor && segmentorIsActive && (
                <Segmentor
                  activeFrame={activeFrame}
                  activeFeature={activeFeature}
                  activeSegmentation={activeSegmentation}
                  features={dicomFeatures}
                  dicom={dicom}
                  dicomPhases={activeDicomPhaseLabels}
                  segmentations={segmentations}
                  setActiveFeature={setActiveFeature}
                  setActiveFrameIndex={setActiveFrameIndex}
                  setActiveSegmentation={setSegmentation}
                  setSegmentations={setSegmentations}
                  setSegmentationsIsSaving={setSegmentationsIsSaving}
                  useOpenContours={useOpenContours}
                  setUseOpenContours={setUseOpenContours}
                  handleCompleteContour={handleTriggerCompleteContour}
                />
              )}
            </SideBarContainer>
          </RightSideBar>
        </ContentContainer>
      </PageContainer>
    </ViewerContainer>
  )
}

export default Viewer
