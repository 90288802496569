import { useCallback, useEffect, useRef, useState } from 'react'
import { Canvas, Path, PencilBrush } from 'fabric'

import { SegmentationCanvasContainer, DrawSection, WrapDrawControl, Button } from './SegmentationCanvas.style'

const combineObjectsWithUnion = (canvasRef) => {
  const canvas = canvasRef.current
  const pathObjects = canvas.getObjects() // Get all objects

  if (pathObjects.length < 2) return // Only combine if there are at least two objects

  // Combine all path data into a single path string
  const combinedPathData = pathObjects.reduce((accumulatedPath, currentPath) => {
    const pathData = currentPath.path // Get the path data from the current fabric.Path object
    return accumulatedPath.concat(pathData) // Combine the path data
  }, [])

  // Create a new fabric.Path object using the combined path data
  const combinedPath = new Path(combinedPathData, {
    fill: '#ff000082',
    strokeWidth: 1,
    color: '#03a9f4',
    selectable: true,
    lockMovementX: true,
    lockMovementY: true,
  })

  // Remove the old Path objects from the canvas
  pathObjects.forEach((obj) => {
    canvas.remove(obj)
  })

  // Add the new combined Path object to the canvas
  canvas.add(combinedPath)
  canvas.renderAll() // Re-render the canvas
}

// Function to create a straight line path between the end of one path and the start of another
export function createLineTo(path) {
  const firstCommand2 = path[0]
  const [__, x2, y2] = firstCommand2 // Start point of path2
  return [['L', x2, y2]]
}

//iterate through each adjacent pair of paths and produce a straight line path between each
export function connectPath(fabricObjects) {
  if (!fabricObjects || fabricObjects.length === 0) {
    return undefined
  }
  const currentPath = fabricObjects[0].path
  const connectorPaths = currentPath

  for (let i = 1; i < fabricObjects.length; i++) {
    const nextPath = fabricObjects[i].path
    if (nextPath[0][0] == 'M') {
      nextPath[0][0] = 'L'
    } else {
      connectorPaths.push(...createLineTo(nextPath))
    }
    connectorPaths.push(...nextPath)
  }
  //final line to close geometry
  connectorPaths.push(...createLineTo(fabricObjects[0].path))

  return connectorPaths
}

const SegmentationCanvas = ({
  activeFrameIndex,
  activeSegmentation,
  setActiveSegmentation,
  dicom,
  scale,
  showMeasurements,
  useOpenContours,
  triggerCompleteContour,
}) => {
  const fabricRef = useRef(null)
  const [activeMode, setActiveMode] = useState('draw')

  const [history, setHistory] = useState([])
  const [mods, setModes] = useState(0)

  const updateModifications = useCallback(
    (savehistory) => {
      if (savehistory === true) {
        const myjson = JSON.stringify(fabricRef.current)
        const origin = JSON.parse(JSON.stringify(history))
        origin.push(myjson)
        setHistory(origin)
      }
    },
    [history],
  )

  useEffect(() => {
    fabricRef.current = new Canvas('Draw', {
      erasable: true,
      isDrawingMode: true,
    })
    fabricRef.current.counter = 0

    const pathStrings = activeSegmentation?.paths

    const paths = pathStrings?.map(
      (pathString) =>
        new Path(pathString, {
          fill: 'red',
          strokeWidth: 2,
          opacity: 0.5,
        }),
    )

    paths?.forEach((path) => fabricRef.current.add(path))

    fabricRef.current.freeDrawingBrush = new PencilBrush(fabricRef.current)
    fabricRef.current.freeDrawingBrush.color = '#03a9f4'
    fabricRef.current.freeDrawingBrush.width = 1
    fabricRef.current.freeDrawingBrush.strokeDashArray = [10]
    fabricRef.current.freeDrawingBrush.fill = '#ff000082'
    fabricRef.current.freeDrawingBrush.id = 'pencil'
    fabricRef.current.on('object:modified', function () {
      updateModifications(true)
    })

    combineObjectsWithUnion(fabricRef)

    fabricRef.current.on('mouse:up', function () {
      if (!fabricRef.current.freeDrawingBrush) {
        return
      }
      if (!useOpenContours) {
        // Closed Contour Mode
        if (fabricRef.current.freeDrawingBrush.id !== 'eraseMode') {
          fabricRef.current.getObjects().forEach((o) => {
            o.fill = '#ff0000c4'
            o.strokeDashArray = []
            o.stroke = '#ff0000c4'
            o.erasable = true
            o.selectable = true
          })
          updateModifications(true)
        }

        const newSegmentation = {
          ...activeSegmentation,
          paths: fabricRef.current.getObjects().map((o) => o.path?.join('')),
          dataUrl: fabricRef.current.toDataURL('image/png'),
          isLabeled: true,
          isFullyVisible: true,
        }

        setActiveSegmentation(newSegmentation)
      } else {
        // Open Contour mode
        if (fabricRef.current.freeDrawingBrush.id !== 'eraseMode') {
          fabricRef.current.getObjects().forEach((o) => {
            o.strokeDashArray = []
            o.stroke = '#ff0000c4'
            o.erasable = true
            o.selectable = true
          })
        }
      }
    })
    fabricRef.current.on('mouse:moving', function () {
      if (fabricRef.current.freeDrawingBrush.id !== 'eraseMode') {
        fabricRef.current.getObjects().forEach((o) => {
          o.fill = '#ff0000c4'
          o.erasable = true
        })
      }
    })

    fabricRef.current.setWidth(dicom?.pixelWidth)
    fabricRef.current.setHeight(dicom?.pixelHeight)

    return () => fabricRef.current.dispose()
  }, [
    dicom?.pixelWidth,
    dicom?.pixelHeight,
    activeSegmentation,
    updateModifications,
    setActiveSegmentation,
    useOpenContours,
  ])

  useEffect(() => {
    handleCompleteContour()
  }, [triggerCompleteContour])

  const handleCompleteContour = () => {
    //connect path
    if (fabricRef.current.getObjects().length > 1) {
      const connectedPaths = connectPath(fabricRef.current.getObjects())
      fabricRef.current.getObjects()[0].path = connectedPaths

      //clear out other objects
      const firstObject = fabricRef.current.getObjects()[0]
      fabricRef.current.clear()
      fabricRef.current.add(firstObject)
    }
    // canvas.clear()
    // canvas.add(path)
    // canvas.renderAll()
    // setContourPoints([]) // Reset points
    if (fabricRef.current.freeDrawingBrush.id !== 'eraseMode') {
      fabricRef.current.getObjects().forEach((o) => {
        o.fill = '#ff0000c4'
      })
      updateModifications(true)
    }
    const newSegmentation = {
      ...activeSegmentation,
      paths: fabricRef.current.getObjects().map((o) => o.path?.join('')),
      dataUrl: fabricRef.current.toDataURL('image/png'),
      isLabeled: true,
      isFullyVisible: true,
    }

    setActiveSegmentation(newSegmentation)
  }

  useEffect(() => setActiveMode('draw'), [activeFrameIndex])

  return (
    <SegmentationCanvasContainer scale={scale} tabIndex="1000">
      <WrapDrawControl />
      <DrawSection />
    </SegmentationCanvasContainer>
  )
}

export default SegmentationCanvas
