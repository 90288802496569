import Toggle from 'react-toggle'

import { Button, ButtonsContainer, ControlsContainer, ToggleContainer, ToggleSectionContainer, Spacer } from './Controls.style'
import './Controls.style.css'

const Controls = ({ activeSegmentation, useOpenContours, setUseOpenContours, setActiveSegmentation, handleCompleteContour}) => {
  const handleToggleOpenContours = () => setUseOpenContours((useOpenContours) => !useOpenContours)
  const handleDeleteOnClick = () =>
    setActiveSegmentation({
      ...activeSegmentation,
      isLabeled: false,
      paths: [],
    })

  return (
    <ControlsContainer>
      <ToggleSectionContainer>
        <ToggleContainer onClick={handleToggleOpenContours}>
          <div>Use Open Contours</div>
          <Toggle checked={useOpenContours} onClick={handleToggleOpenContours} readOnly />
        </ToggleContainer>
        {useOpenContours && (
          <>
            <Spacer />
            <Button onClick={handleCompleteContour}>Complete Contour</Button>
          </>
        )}
      </ToggleSectionContainer>
      <ToggleSectionContainer>
        <ButtonsContainer>
          <Button onClick={handleDeleteOnClick}>Delete</Button>
        </ButtonsContainer>
      </ToggleSectionContainer>
    </ControlsContainer>
  )
}

export default Controls
